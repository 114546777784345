<template>
  <div class="relative" style="margin: -30px; height: calc(100vh - 60px)">
    <div class="states flex h-full">
      <div class="flex-shrink-0 w-auto border-r" style="width: 300px">
        <header class="border-b py-2 font-medium px-3">
          <div class="mb-2">
            <el-dropdown @command="handleChangeShop" trigger="click">
              <span class="el-dropdown-link">
                {{ shopName }}
                <i v-if="loading" class="el-icon-loading el-icon--right"></i>
                <i v-else class="el-icon-arrow-down el-icon--right"></i>
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="shop in shops"
                  :key="shop.id"
                  :command="shop.id"
                  :disabled="shop.id === filters.shop_id"
                  >{{ shop.company }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="flex justify-center justify-between">
            <el-dropdown class="" @command="handleChangeState" trigger="click">
              <span class="el-dropdown-link inline">
                {{ state_title[filters.state] }}
                ({{ ordersCount }})
                <i v-if="loading" class="el-icon-loading el-icon--right"></i>
                <i v-else class="el-icon-arrow-down el-icon--right"></i>
                <br />
                <span>{{ numberFormat(ordersSum) }} тг</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="state in states"
                  :key="state"
                  :command="state"
                  :disabled="state === filters.state"
                  >{{ state_title[state] || state }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-button
              v-if="checkedOrders.length"
              size="mini"
              :disabled="downloading"
              :icon="downloading ? 'el-icon-loading' : 'el-icon-download'"
              @click="downloadWayills"
            ></el-button>
          </div>
        </header>
        <main
          style="height: calc(100vh - 145px); overflow-y: auto"
          class="w-full"
        >
          <ul v-if="orders">
            <li
              v-for="order in orders"
              :key="order.id"
              class="border-b p-2 cursor-pointer hover:bg-gray-100 flex items-start"
              :class="{ 'bg-gray-100': orderId === order.id }"
            >
              <el-checkbox
                @change="selectOrder(order.id)"
                :value="checkedOrders.includes(order.id)"
                class="pr-2"
              ></el-checkbox>
              <div @click="() => openOrder(order.id)" class="w-full">
                <div class="flex justify-between items-center">
                  <div>
                    {{ order.attributes.customer.firstName }}
                    {{ order.attributes.customer.lastName }}
                  </div>
                  <div class="text-xs text-gray-500">
                    {{ dateFormat(order.attributes.creationDate) }}
                  </div>
                </div>
                <div class="text-blue-500">#{{ order.attributes.code }}</div>
                <div class="text-gray-400 text-xs mt-1">
                  {{ order.attributes.totalPrice }} тг
                </div>
                <div
                  :class="order.attributes.status"
                  class="bg-gray-100 inline-block text-xs p-1 px-2 rounded mt-2 text-gray-500"
                >
                  {{
                    statuses[order.attributes.status] || order.attributes.status
                  }}
                </div>
              </div>
            </li>
          </ul>
        </main>
      </div>
      <div class="w-full">
        <router-view :shopId="filters.shop_id"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadOrders, downloadWayills, getOrders } from "@/api/sales";
import { getShops } from "@/api/branch";
import { format } from "date-fns";
import states from "@/helpers/states";

export default {
  name: "index",
  data: () => ({
    orders: [],
    shops: [],
    checkedOrders: [],
    states: [
      "NEW",
      "SIGN_REQUIRED",
      "PICKUP",
      "DELIVERY",
      "KASPI_DELIVERY",
      "WAITING",
      "HANDED",
    ],
    statuses: {
      APPROVED_BY_BANK: "Одобрен банком",
      ACCEPTED_BY_MERCHANT: "Принят на обработку продавцом",
      COMPLETED: "Завершён",
      CANCELLED: "Отменён",
      CANCELLING: "Ожидает отмены",
      KASPI_DELIVERY_RETURN_REQUESTED: "Ожидает возврата",
      RETURN_ACCEPTED_BY_MERCHANT: "Ожидает решения по возврату",
      RETURNED: "Возвращён",
    },

    state_title: states,
    downloading: false,
    loading: false,

    filters: {
      state: "KASPI_DELIVERY",
      shop_id: 1,
    },
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    shopName() {
      const shop = this.shops.find((shop) => shop.id === this.filters.shop_id);
      return (shop && shop.company) || null;
    },
    ordersSum() {
      if (this.orders && this.orders.length) {
        return this.orders.reduce((a, b) => b.attributes.totalPrice + a, 0);
      }
      return 0;
    },
    ordersCount() {
      if (this.orders && this.orders.length) {
        return this.orders.length;
      }
      return 0;
    },
    orderId() {
      return this.$route.params.orderId;
    },
  },
  async created() {
    this.shops = await getShops();
    await this.getOrders();
  },
  methods: {
    async getOrders() {
      this.loading = true;
      if (["HANDED", "WAITING"].includes(this.filters.state)) {
        const res = await getOrders({
          ...this.filters,
          state: "KASPI_DELIVERY",
        });
        switch (this.filters.state) {
          case "HANDED":
            this.orders = res.data.filter(
              (order) =>
                !!order.attributes.kaspiDelivery.courierTransmissionDate
            );
            break;
          case "WAITING":
            this.orders = res.data.filter(
              (order) => !order.attributes.kaspiDelivery.courierTransmissionDate
            );
            break;
        }
      } else {
        const res = await getOrders(this.filters);
        this.orders = res.data;
      }

      this.loading = false;
    },
    async download() {
      this.downloading = true;
      await downloadOrders("DELIVERY");
      this.downloading = false;
    },
    async handleChangeState(state) {
      this.filters.state = state;
      await this.getOrders();
    },
    async handleChangeShop(shopId) {
      this.filters.shop_id = shopId;
      await this.getOrders();
    },
    dateFormat(timestamp) {
      return format(timestamp, "dd.MM HH:mm");
    },
    openOrder(orderId) {
      this.$router.push({ name: "order-view", params: { orderId: orderId } });
    },

    numberFormat(number) {
      return new Intl.NumberFormat().format(Math.round(number));
    },
    selectOrder(orderId) {
      if (this.checkedOrders.includes(orderId)) {
        this.checkedOrders = this.checkedOrders.filter((id) => id !== orderId);
        return;
      }
      this.checkedOrders.push(orderId);
    },

    async downloadWayills() {
      this.downloading = true;
      try {
        const res = await downloadWayills(
          this.checkedOrders,
          this.filters.shop_id
        );
        this.downloading = false;
        const domain = process.env["VUE_APP_DOMAIN"];
        this.$alert("Накладные успешно созданы", "Внимание", {
          confirmButtonText: "Скачать накладные",
          callback: () => {
            window.open(`${domain}/waybills/${res.result.fileHash}`, "_blank");
          },
        });
        console.log(res);
      } catch (e) {
        this.downloading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.APPROVED_BY_BANK {
  background-color: #29bb89;
  color: #fff;
}
.ACCEPTED_BY_MERCHANT {
}
.COMPLETED {
  background-color: green;
}
.CANCELLED {
  background-color: #f54748;
}
.CANCELLING {
  background-color: #ffd8cc;
}
.KASPI_DELIVERY_RETURN_REQUESTED,
.RETURN_ACCEPTED_BY_MERCHANT {
  background-color: #ffe268;
}
.RETURNED {
  background-color: #51c2d5;
}
</style>
