const states = {
  NEW: "Новые",
  SIGN_REQUIRED: "На подписании",
  PICKUP: "Самовывоз",
  DELIVERY: "Доставка",
  KASPI_DELIVERY: "Kaspi Доставка",
  WAITING: "Ожидает курьера",
  HANDED: "Передан курьеру",
};

export const getStateTitle = (state) => {
  return states[state] || state;
};

export default states;
