import apiClient from "@/api/apiClient";

export const getOrders = async ({ state, shop_id }) => {
  const result = await apiClient.get("/api/sales/orders", {
    params: { state, shop_id },
  });
  return result.data.orders;
};

export const downloadWayills = async (orders, shopId) => {
  const result = await apiClient.get("/api/sales/download-waybills", {
    params: { orders: JSON.stringify(orders), shop_id: shopId },
  });
  return result.data;
};

export const downloadOrders = async (state) => {
  const result = await apiClient.get("/api/sales/orders", {
    params: { state, action: "download" },
    responseType: "blob",
  });

  let type = result.headers["content-type"];
  let blob = new Blob([result.data], { type: type });
  let filename = "Заказы-на-доставке.xlsx";

  let URL = window.URL || window.webkitURL;
  let downloadUrl = URL.createObjectURL(blob);

  let a = document.createElement("a");
  if (typeof a.download === "undefined") {
    window.location = downloadUrl;
  } else {
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  setTimeout(function () {
    URL.revokeObjectURL(downloadUrl);
  }, 100); // cleanup

  return result.data;
};

export const getOrder = async (orderId, shopId) => {
  const result = await apiClient.get(`/api/sales/order/${orderId}`, {
    params: {
      shop_id: shopId,
    },
  });
  return result.data;
};

export const updateOrderItem = async (orderId, imeis) => {
  const result = await apiClient.post(`/api/sales/order/${orderId}`, {
    item: imeis,
  });
  return result.data;
};
